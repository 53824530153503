import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import api from "src/api/api";
import { businessProposalUserRoute } from "src/constant/app_route_names";
import useAuth from "../../hooks/useAuth";

const AccessRedirect = () => {
  const { setAuth } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Extract parameters from URL
    const id = searchParams.get("id");
    const email = searchParams.get("email");
    const user_id = searchParams.get("user_id");
    const username = searchParams.get("username");
    const accessToken = searchParams.get("accessToken");

    // Validate necessary parameters
    if (!id || !accessToken) {
      console.error("Missing required parameters");
      navigate("/"); // Redirect to login if data is missing
      return;
    }

    // Store user data
    const userData = {
      id,
      email,
      user_id,
      username,
      accessToken,
      role: ["user"],
    };

    // Set authentication context (if applicable)
    setAuth(userData);

    // Store in local storage
    localStorage.setItem("userData", JSON.stringify(userData));

    // Set default headers for API requests
    api.defaults.headers.common["Authorization"] = accessToken;

    // Navigate to the business proposal user route
    navigate(`/${businessProposalUserRoute}`, {
      replace: true,
      state: { id },
    });
  }, [searchParams, navigate, setAuth]);

  return <div>Processing login...</div>;
};

export default AccessRedirect;
