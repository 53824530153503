import React, { useState, useEffect, Fragment, useRef } from "react";
import { LogOut, Minimize, HelpCircle, User, Settings } from "react-feather";
import { useNavigate } from "react-router-dom";
import man from "../../assets/images/dashboard/profile.jpg";
import useAuth from "../../hooks/useAuth";
import { installerRole, userRole } from "src/constant/app_constants";

const Rightbar = () => {
  const navigate = useNavigate();
  const [moonlight, setMoonlight] = useState(false);
  const [searchresponsive, setSearchresponsive] = useState(false);
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  var { auth, setAuth } = useAuth();

  const logout = async () => {
    setAuth({});
    const to = auth?.role?.toString() === userRole ? "/" : auth?.role?.toString() === installerRole ? "/installer/login" : "/admin";
    navigate(to, { replace: true });
    window.location.reload();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  function toCamelCase(inputString) {
    const words = inputString.split("_");
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const handleLogout = () => {
    setShowDropdown(false);
    logout();
  };

  const goFull = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <Fragment>
      <div className="nav-right col pull-right right-menu p-0">
        <ul className="nav-menus">
          {auth?.role?.toString() !== "admin" && (
            <li className="contact">
              <a href="/user/contact-us">
                <HelpCircle className="text-dark" />
              </a>
            </li>
          )}

          <li className="maximize">
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Minimize />
            </a>
          </li>

          <li className="profile-nav p-0" ref={dropdownRef} style={{ position: 'relative' }}>
            <div 
              className="media profile-media d-flex align-items-center" 
              onClick={() => setShowDropdown(!showDropdown)}
              style={{ cursor: 'pointer', padding: '4px' }}
            >
              <div className="user-avatar me-2">
                <img 
                  src={man} 
                  alt="user"
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    border: "2px solid #eee"
                  }}
                />
              </div>
              <div className="media-body d-none d-md-block">
                <span className="d-block fw-bold" style={{ fontSize: '14px', color: '#333' }}>
                  {auth?.username || "User"}
                </span>
                <p className="mb-0" style={{ fontSize: '12px', color: '#666' }}>
                  {auth?.role ? toCamelCase(auth?.role.toString()) : ""}{" "}
                  <i className={`fa fa-angle-down ms-1 ${showDropdown ? 'fa-rotate-180' : ''}`} 
                     style={{ transition: 'transform 0.2s' }}></i>
                </p>
              </div>
            </div>
            
            <div 
              style={{
                display: showDropdown ? 'block' : 'none',
                position: 'absolute',
                top: '100%',
                right: '0',
                width: '220px',
                backgroundColor: '#fff',
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                padding: '8px 0',
                marginTop: '8px',
                zIndex: 1000,
              }}
            >
              <div style={{ padding: '8px 16px', borderBottom: '1px solid #eee' }}>
                <div style={{ fontSize: '12px', color: '#666' }}>Signed in as</div>
                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{auth?.username}</div>
              </div>
              
              {/* Profile and Settings buttons commented out until pages are ready
              <div style={{ padding: '4px 0' }}>
                <button 
                  className="dropdown-item"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    padding: '8px 16px',
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                    color: '#333',
                    fontSize: '14px'
                  }}
                  onMouseEnter={e => e.target.style.backgroundColor = '#f5f5f5'}
                  onMouseLeave={e => e.target.style.backgroundColor = 'transparent'}
                >
                  <User size={16} style={{ marginRight: '12px' }} />
                  <span>Profile</span>
                </button>
                
                <button 
                  className="dropdown-item"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    padding: '8px 16px',
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                    color: '#333',
                    fontSize: '14px'
                  }}
                  onMouseEnter={e => e.target.style.backgroundColor = '#f5f5f5'}
                  onMouseLeave={e => e.target.style.backgroundColor = 'transparent'}
                >
                  <Settings size={16} style={{ marginRight: '12px' }} />
                  <span>Settings</span>
                </button>
              </div>
              
              <div style={{ height: '1px', background: '#eee', margin: '4px 0' }} />
              */}
              
              <div style={{ padding: '4px 0' }}>
                <button
                  onClick={handleLogout}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    padding: '8px 16px',
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                    color: '#dc3545',
                    fontSize: '14px'
                  }}
                  onMouseEnter={e => e.target.style.backgroundColor = '#f5f5f5'}
                  onMouseLeave={e => e.target.style.backgroundColor = 'transparent'}
                >
                  <LogOut size={16} style={{ marginRight: '12px' }} />
                  <span>Sign out</span>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default Rightbar;
