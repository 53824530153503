import api from "./api";

const UsersService = {
  addUserBill(payload) {
    return api.post("voltflo/add_user_bill_new", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getUserBills(user_id) {
    return api.get(`voltflo/get_user_bills/${user_id}`);
  },

  getUserBillDetails(payload) {
    return api.put("voltflo/get_user_bill_details", payload);
  },

  deleteUserBills(payload) {
    return api.put("voltflo/delete_user_bill", payload);
  },

  updateUserBill(payload) {
    return api.put("voltflo/update_user_bill", payload);
  },

  saveUserBill(payload) {
    return api.put("voltflo/save_user_bill", payload);
  },

  getUserLastBill(payload) {
    return api.put("voltflo/get_user_last_bill", payload);
  },

  updateMonthConsumption(payload) {
    return api.put("voltflo/update_month_consumption", payload);
  },
  addManualConsumption(payload) {
    return api.put("voltflo/add_manual_month_consumption", payload);
  },
  
};

export default UsersService;
