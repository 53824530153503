import {
  adminRole,
  installerRole,
  salesAgentRole,
  userRole,
} from "./app_constants";

export const loginRoute = "/";
export const registerRoute = "/register";

export const publicLocationRoute = `/location`;
export const publicSolarRoute = `/map`;
export const overviewRoute = `/${adminRole}/overview`;
export const dashboardRoute = `${adminRole}/dashboards`;
export const sitesRoute = `${adminRole}/all_sites_map`;
export const sitesTableRoute = `${adminRole}/all_sites_list`;

export const monitoringRoute = `${adminRole}/monitoring`;
export const settingsRoute = `${adminRole}/settings`;
export const devicesRoute = `${adminRole}/devices`;
export const reportingRoute = `${adminRole}/reporting`;
export const projectsRoute = `${adminRole}/projects`;
export const environmentalRoute = `${adminRole}/environmental`;
export const financialRoute = `${adminRole}/financial`;
export const customMix1Route = `${adminRole}/custom_mix_1`;
export const euOnlyRoute = `${adminRole}/eu_only`;
export const usaOnlyRoute = `${adminRole}/usa_only`;
export const pagesRoute = `${adminRole}/pages`;
export const monitoringSingleSiteRoute = `${adminRole}/monitoring_single_site`;
export const FuelMixRoute = `${adminRole}/fuel_mix`;
export const NewSiteRoute = `${adminRole}/new_site`;
export const AccessNewRoute = `${adminRole}/access_new_site`;
export const integrationsAPIRoute = `${adminRole}/integrations_api`;
export const enerygyInsightsRoute = `${adminRole}/energy_insights`;
export const savedOpportunitiesRoute = `/saved_opportunities/:id`;

export const openSolarRoute = `${adminRole}/opensolar`;
export const openSolarLoginRoute = `${adminRole}/opensolar/login`;
export const openSolarProjectsRoute = `${adminRole}/opensolar/projects`;
export const savedOpportunitiesRouteLink = `/saved_opportunities/`;
// export const savedOpportunitiesRouteLink = `${adminRole}/saved_opportunities/`;
export const dashboardRouteMenu = `${adminRole}/dashboards`;

export const designRoute = `${adminRole}/design`;

export const AlertEmailsTabRoute = `${adminRole}/alert_emails`;

export const businessesTableRoute = `/${adminRole}/businesses`;

// Site Settings
export const siteBillDetailsRoute = `${adminRole}/site/bill_details`;
export const SiteSettingsTabRoute = `${adminRole}/site_settings`;
export const siteAddBillRoute = `${adminRole}/site/add_bill`;

// Bills
export const billsRoute = `${adminRole}/bills`;
export const addBNewBillRoute = `${adminRole}/add_bill`;
export const billDetailsRoute = `${adminRole}/bill_details`;

// Prospect
export const prospectDemoRoute = `${adminRole}/search_prospect`;
export const savedProspectsRoute = `${adminRole}/saved_prospects`;
export const prospectDemoAgentRoute = `${salesAgentRole}/search_prospect`;
export const savedProspectsAgentRoute = `${salesAgentRole}/saved_prospects`;

export const searchPlaceRoute = `${adminRole}/search_place`;
export const placeTableRoute = `${adminRole}/places`;

// Business Proposal
export const businessProposalAdminRoute = `${adminRole}/business_proposal/`;

// Hot Lead
export const hotLeadAgentRoute = `${salesAgentRole}/hot_lead`;
export const hotLeadAdminRoute = `${adminRole}/hot_lead`;
export const hotLeadDetailsAdminRoute = `${adminRole}/hot_lead_details`;
export const adminInstallersRoute = `${adminRole}/installers`;
export const adminCustomersRoute = `${adminRole}/customers`;
export const hotLeadDetailsAgentRoute = `${salesAgentRole}/hot_lead_details`;
export const adminInstallersProfileRoute = `${adminRole}/installers-profile`;

export const mapLiveProspectSitesAgentRoute = `/map`;

export const contractPreparedRoute = `${salesAgentRole}/contracts_prepared`;

export const contractSignedRoute = `${salesAgentRole}/contracts_signed`;

// Finance
export const financeRoute = `${adminRole}/finance`;
export const environmentRoute = `${adminRole}/environment`;

// Sales Agent Routes

export const searchPlaceAgentRoute = `${salesAgentRole}/search_place`;
export const placeTableAgentRoute = `${salesAgentRole}/places`;
export const businessProposalRoute = `${salesAgentRole}/business_proposal`;

// Live Sites

export const liveSitesListAdminRoute = `${adminRole}/live_site_list`;
export const liveSitesMapAdminRoute = `${adminRole}/live_site_map`;

export const liveSitesListAgentRoute = `${salesAgentRole}/live_site_list`;
export const liveSitesMapAgentRoute = `${salesAgentRole}/live_site_map`;

// User Role Routes
export const businessProposalUserRoute = `${userRole}/solar_proposal`;
export const tailorProposalUserRoute = `${userRole}/tailor_proposal`;
export const billsUserRoute = `${userRole}/bills`;
export const loanCalculatorRoute = `${userRole}/loan_calculator`;
export const addUserNewBillRoute = `${userRole}/add_bill`;
export const updateUserBillRoute = `${userRole}/update_bill`;
export const userBillDetailsRoute = `${userRole}/bill_details`;
export const installersRoute = `${userRole}/installers`;
export const NetZeroRoute = `${userRole}/net-zero`;
export const ContactUsRoute = `${userRole}/contact-us`;
export const CompletionRoute = `${userRole}/completion`;
export const DummyProposalRoute = `/user/verification_pending`;

export const installerLogin = `${installerRole}/login`;
export const installerSignup = `${installerRole}/signup`;
export const installerProfileSetup = `${installerRole}/profile-setup`;
export const installerDashboard = `${installerRole}/dashboard`;
export const installerJobs = `${installerRole}/jobs`;
export const installerProfile = `${installerRole}/profile`;
export const installerSettings = `${installerRole}/settings`;
