import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ADMIN,
  getAdminMenu,
  INSTALLERMENU,
  SALESAGENT,
  USERMENU,
} from "./menu";
import { ArrowRight, ArrowLeft, Grid } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import configDB from "../../data/customizer/config";
import {
  addBNewBillRoute,
  billDetailsRoute,
  billsRoute,
  businessesTableRoute,
  dashboardRoute,
  dashboardRouteMenu,
  hotLeadAdminRoute,
  hotLeadAgentRoute,
  hotLeadDetailsAdminRoute,
  hotLeadDetailsAgentRoute,
  placeTableAgentRoute,
  placeTableRoute,
  prospectDemoRoute,
  searchPlaceAgentRoute,
  searchPlaceRoute,
  siteAddBillRoute,
  siteBillDetailsRoute,
  SiteSettingsTabRoute,
  sitesTableRoute,
} from "src/constant/app_route_names";
import {
  adminRole,
  installerRole,
  salesAgentRole,
  userRole,
} from "src/constant/app_constants";
import LogoComponent from "src/pages/auth/LogoComponent";

const Sidebar = (props) => {
  const id = window.location.pathname;
  const layout = id ? id : "Dubai";
  const location = useLocation();
  const [user, setUser] = useState({});

  // eslint-disable-next-line

  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [sidebartoogle, setSidebartoogle] = useState(true);
  const wrapper =
    useSelector((content) => content.Customizer.sidebar_types.type) ||
    configDB.data.settings.sidebar.type;

  useEffect(() => {
    const user_data = JSON.parse(localStorage.getItem("userData"));
    setUser(user_data);

    if (window.innerWidth < 786) {
      setSidebartoogle(false);
      document.querySelector(".page-header").className = "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon";
    }
  }, []);

  const MENUITEMS =
    user?.role?.toString() === adminRole
      ? getAdminMenu()
      : user?.role?.toString() === salesAgentRole
      ? SALESAGENT
      : user?.role?.toString() === userRole
      ? USERMENU
      : user?.role?.toString() === installerRole
      ? INSTALLERMENU
      : [];
  // const MENUITEMS = INSTALLERMENU;
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  const handleScroll = () => {
    if (window.scrollY > 400) {
      if (
        configDB.data.settings.sidebar.type.split(" ").pop() ===
          "material-type" ||
        configDB.data.settings.sidebar.type.split(" ").pop() ===
          "advance-layout"
      )
        document.querySelector(".sidebar-main").className =
          "sidebar-main hovered";
    } else {
      document.querySelector(".sidebar-main").className = "sidebar-main";
    }
  };

  const getUrl = () => {
    const currentUrl =
      window.location.pathname === `/${SiteSettingsTabRoute}` ||
      window.location.pathname === `/${siteBillDetailsRoute}` ||
      window.location.pathname === `/${siteAddBillRoute}`
        ? `/${sitesTableRoute}`
        : window.location.pathname === `/${billDetailsRoute}` ||
          window.location.pathname === `/${addBNewBillRoute}`
        ? `/${billsRoute}`
        : window.location.pathname === `${businessesTableRoute}`
        ? `/${prospectDemoRoute}`
        : window.location.pathname === `/${hotLeadDetailsAdminRoute}`
        ? `/${hotLeadAdminRoute}`
        : window.location.pathname === `/${hotLeadDetailsAgentRoute}`
        ? `/${hotLeadAgentRoute}`
        : window.location.pathname === `/${placeTableAgentRoute}`
        ? `/${searchPlaceAgentRoute}`
        : window.location.pathname === `/${placeTableRoute}`
        ? `/${searchPlaceRoute}`
        : window.location.pathname;
    return currentUrl;
  };

  useEffect(() => {
    document.querySelector(".left-arrow").classList.add("d-none");
    window.addEventListener("resize", handleResize);
    handleResize();
    const currentUrl = getUrl();

    const updatedMenuData = MENUITEMS.map((menuGroup) => {
      const updatedItems = menuGroup?.Items?.map((item) => {
        if (item.path !== undefined && `/${item.path}` === currentUrl) {
          item.active = true;
        } else {
          item.active = false;
        }

        if (item.children) {
          item.children.forEach((child) => {
            if (child.path !== undefined && `/${child.path}` !== currentUrl) {
              child.active = false;
            }

            if (child.path !== undefined && `/${child.path}` === currentUrl) {
              item.active = true;
              child.active = true;
            }
          });
        }

        return item;
      });

      return {
        ...menuGroup,
        Items: updatedItems,
      };
    });
    setMainMenu(updatedMenuData);
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [layout, user]);

  const handleMenuClick = (menu, childMenu) => {
    const currentUrl = getUrl();
    const updatedMenuData = MENUITEMS.map((menuGroup) => {
      const updatedItems = menuGroup.Items.map((item) => {
        if (item.path !== currentUrl) {
          if (item === menu) {
            item.active = true;
          } else {
            item.active = false;
          }
          if (item.children) {
            item.children.forEach((child) => {
              if (child !== childMenu) {
                child.active = false;
              }
            });
            if (item.children.includes(childMenu)) {
              item.active = true;
              childMenu.active = true;
            }
          }
        }
        return item;
      });

      return {
        ...menuGroup,
        Items: updatedItems,
      };
    });
    setMainMenu({ mainmenu: updatedMenuData });
  };

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  };

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570);
      } else {
        setMargin(-3464);
      }
      document.querySelector(".right-arrow").classList.add("d-none");
      document.querySelector(".left-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += -width));
      document.querySelector(".left-arrow").classList.remove("d-none");
    }
  };
  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0);
      document.querySelector(".left-arrow").classList.add("d-none");
      document.querySelector(".right-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += width));
      document.querySelector(".right-arrow").classList.remove("d-none");
    }
  };
  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active");
    document.querySelector(".sidebar-link").classList.remove("active");
  };
  const activeClass = () => {
    // document.querySelector(".sidebar-link").classList.add("active")
    // document.querySelector(".bg-overlay1").classList.add("active")
  };
  const openCloseSidebar = (toggle) => {
    if (toggle) {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";
    } else {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
    }
  };
  const responsiveSidebar = () => {
    document.querySelector(".page-header").className = "page-header close_icon";
    document.querySelector(".sidebar-wrapper").className =
      "sidebar-wrapper close_icon";
  };

  return (
    <Fragment>
      <div
        className={`bg-overlay1`}
        onClick={() => {
          closeOverlay();
        }}
      ></div>
      <div className="sidebar-wrapper">
        <div className="logo-wrapper">
          {/* <Link to={`${process.env.PUBLIC_URL}${dashboardRoute}`}> */}
          <LogoComponent />
          {/* <h6
            style={{
              color: "grey",
              fontSize: 20,
              display: "inline-block",
              fontWeight: 400,
              letterSpacing: 2,
            }}
          >
            Voltflo
          </h6> */}

          {/* </Link> */}
          <div
            className="back-btn inline-block"
            onClick={() => responsiveSidebar()}
          >
            <i className="fa fa-angle-left"></i>
          </div>
          <div
            className="toggle-sidebar"
            onClick={() => openCloseSidebar(sidebartoogle)}
          >
            <Grid className="status_toggle middle sidebar-toggle" />
          </div>
        </div>
        <div className="logo-icon-wrapper">
          {/* <Link to={`${process.env.PUBLIC_URL}${dashboardRouteMenu}`}> */}
          <img
            className="img-fluid"
            src={require("../../assets/images/logo/logo.png")}
            alt=""
            width={35}
          />
          {/* </Link> */}
        </div>
        <nav className="sidebar-main">
          <div className="left-arrow" onClick={scrollToLeft}>
            <ArrowLeft />
          </div>
          <div
            id="sidebar-menu"
            style={
              wrapper.split(" ").includes("horizontal-wrapper")
                ? { marginLeft: margin + "px" }
                : { margin: "0px" }
            }
          >
            <ul className="sidebar-links custom-scrollbar">
              <li className="back-btn">
                <div className="mobile-back text-right">
                  <span>{"Back"}</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                </div>
              </li>
              {MENUITEMS.map((Item, i) => (
                <Fragment key={i}>
                  {Item.Items.map((menuItem, i) => (
                    <li className="sidebar-list" key={i}>
                      {menuItem.type === "sub" ? (
                        <a
                          href="javascript"
                          className={`sidebar-link sidebar-title ${
                            menuItem.active ? activeClass() : ""
                          }`}
                          onClick={(event) => {
                            event.preventDefault();
                            handleMenuClick(menuItem, menuItem);
                          }}
                        >
                          {/* <Fragment  style={{color: menuItem.disabled === true ? "#BEBFC5" :  menuItem.active && !menuItem.disabled ?"#7366ff" : menuItem.active && menuItem.disabled ?"#7366ff": "black"}}> {menuItem?.icon ? menuItem?.icon : null}  </Fragment> */}
                          {menuItem?.icon ? menuItem?.icon : null}
                          <span
                            style={{
                              color:
                                menuItem.disabled === true
                                  ? "#BEBFC5"
                                  : menuItem.active && !menuItem.disabled
                                  ? "#7366ff"
                                  : menuItem.active && menuItem.disabled
                                  ? "#7366ff"
                                  : "black",
                            }}
                          >
                            {menuItem.title}{" "}
                          </span>
                          {menuItem.badge ? (
                            <label className={menuItem.badge}>
                              {menuItem.badgetxt}
                            </label>
                          ) : (
                            ""
                          )}
                          <div className="according-menu">
                            {menuItem.active ? (
                              <i className="fa fa-angle-down"></i>
                            ) : (
                              <i className="fa fa-angle-right"></i>
                            )}
                          </div>
                        </a>
                      ) : (
                        ""
                      )}
                      {menuItem.type === "link" ? (
                        <Link
                          to={menuItem.path}
                          className={`sidebar-link sidebar-title link-nav  ${
                            menuItem.active ? "active" : ""
                          }`}
                          onClick={() => {
                            if (!menuItem.disabled) {
                              handleMenuClick(Item, menuItem);
                            }
                          }}
                        >
                          {menuItem?.icon ? menuItem?.icon : null}
                          <span
                            style={{
                              color:
                                menuItem.disabled === true
                                  ? "lightGrey"
                                  : menuItem.active && !menuItem.disabled
                                  ? "#7366ff"
                                  : menuItem.active && menuItem.disabled
                                  ? "#7366ff"
                                  : "black",
                            }}
                          >
                            {menuItem.title}
                          </span>
                          {menuItem.badge ? (
                            <label className={menuItem.badge}>
                              {menuItem.badgetxt}
                            </label>
                          ) : (
                            ""
                          )}
                        </Link>
                      ) : (
                        ""
                      )}
                      {menuItem.children ? (
                        <ul
                          className="sidebar-submenu"
                          style={
                            menuItem.active
                              ? sidebartoogle
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "block", opacity: 1 }
                              : { display: "none" }
                          }
                        >
                          {menuItem.children.map((childrenItem, index) => {
                            return (
                              <li key={index}>
                                {childrenItem.type === "sub" ? (
                                  <a
                                    href="javascript"
                                    className={`${
                                      childrenItem.active ? "active" : ""
                                    }`}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      handleMenuClick(menuItem, childrenItem);
                                    }}
                                  >
                                    {/* <span>  {childrenItem.title} </span> */}
                                    <span
                                      style={{
                                        color:
                                          childrenItem.disabled === true
                                            ? "lightGrey"
                                            : childrenItem.active &&
                                              !childrenItem.disabled
                                            ? "#7366ff"
                                            : childrenItem.active &&
                                              childrenItem.disabled
                                            ? "#7366ff"
                                            : "black",
                                      }}
                                    >
                                      {" "}
                                      {childrenItem.title}
                                    </span>
                                    {/* <span  >  {childrenItem.title} </span> */}
                                    {/* <span   style={{color: childrenItem.disabled === true ? "lightGrey" : childrenItem.active && !childrenItem.disabled ?"#7366ff" : childrenItem.active && childrenItem.disabled ?"#7366ff": "black"}}>  {childrenItem.title} </span> */}
                                    <span className="sub-arrow">
                                      <i className="fa fa-chevron-right"></i>
                                    </span>
                                    <div className="according-menu">
                                      {childrenItem.active ? (
                                        <i className="fa fa-angle-down"></i>
                                      ) : (
                                        <i className="fa fa-angle-right"></i>
                                      )}
                                    </div>
                                  </a>
                                ) : (
                                  ""
                                )}
                                {childrenItem.type === "link" ? (
                                  <Link
                                    to={childrenItem.path}
                                    className={`${
                                      childrenItem.active ? "active" : ""
                                    }`}
                                    style={{ marginLeft: 5 }}
                                    onClick={() =>
                                      handleMenuClick(menuItem, childrenItem)
                                    }
                                  >
                                    {/* <childrenItem.icon /> */}
                                    <label
                                      style={{
                                        color:
                                          childrenItem.disabled === true
                                            ? "lightGrey"
                                            : childrenItem.active &&
                                              !childrenItem.disabled
                                            ? "#7366ff"
                                            : childrenItem.active &&
                                              childrenItem.disabled
                                            ? "#7366ff"
                                            : "black",
                                      }}
                                    >
                                      {childrenItem.title}
                                    </label>
                                    {childrenItem.badge ? (
                                      <label
                                        className={childrenItem.badge}
                                        style={{
                                          marginTop: -5,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {childrenItem.badgetxt}
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                  </Link>
                                ) : (
                                  ""
                                )}
                                {childrenItem.children ? (
                                  <ul
                                    className="nav-sub-childmenu submenu-content"
                                    style={
                                      childrenItem.active
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                  >
                                    {childrenItem.children.map(
                                      (childrenSubItem, key) => (
                                        <li key={key}>
                                          {childrenSubItem.type === "link" ? (
                                            <Link
                                              to={childrenSubItem.path}
                                              className={`${
                                                childrenSubItem.active
                                                  ? "active"
                                                  : ""
                                              }`}
                                              onClick={() =>
                                                handleMenuClick(
                                                  childrenItem,
                                                  childrenSubItem
                                                )
                                              }
                                            >
                                              {childrenSubItem.title}
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </Fragment>
              ))}
            </ul>
          </div>
          <div className="right-arrow" onClick={scrollToRight}>
            <ArrowRight />
          </div>
        </nav>
      </div>
    </Fragment>
  );
};
export default Sidebar;
